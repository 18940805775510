import React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { type FieldConfig, useField } from 'formik';

import { useTranslation } from '../Translate';

export type FieldAttributes<V> = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  React.SelectHTMLAttributes<HTMLSelectElement> &
  FieldConfig<V> & {
    name: string;
    as?:
      | 'select'
      | 'input'
      | 'textarea'
      | React.ComponentType<React.InputHTMLAttributes<HTMLInputElement> & FieldConfig<V>>;
    placeholderData?: Record<string, unknown>;
    fractions?: number;
  };

export default function Field<V>({ as, placeholderData, fractions, ...props }: FieldAttributes<V>) {
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  const Component: React.ElementType = as ?? 'input';
  if (!props.type) {
    props.type = 'text';
  }
  if (props.placeholder?.match(/\w+\.\w+/)) {
    props.placeholder = t(props.placeholder, {
      data: placeholderData,
      default: props.placeholder,
    });
  }

  const value = useMemo(() => {
    let value: UnsafeAny = field.value;
    if (props.type === 'number' && typeof fractions === 'number' && typeof value === 'number') {
      value = Math.round(value * Math.pow(10, fractions)) / Math.pow(10, fractions);
    }
    return value ?? '';
  }, [field.value, props.type, fractions]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value: UnsafeAny = event.target.value;
      if (props.type === 'number') {
        value = event.target.valueAsNumber ?? 0;
      }
      event.target.value = value;
      field.onChange(event);
    },
    [props.type, field.onChange],
  );

  return (
    <Component
      {...props}
      {...field}
      className={cn('form-control', { 'is-invalid': meta.touched && !!meta.error }, props.className)}
      onChange={handleChange}
      step={(props.step ?? fractions) ? 1 / Math.pow(10, fractions) : null}
      value={value}
    />
  );
}

export interface InputAttributes extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange(value: string): void | boolean;
}

export function Input({ value, onChange, ...props }: InputAttributes) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.target.value;
      return onChange(nextValue);
    },
    [onChange],
  );

  if (!props.type) {
    props.type = 'text';
  }
  if (props.placeholder?.match(/\w+\.\w+/)) {
    props.placeholder = t(props.placeholder, { default: props.placeholder });
  }

  return <input {...props} className={cn('form-control', props.className)} onChange={handleChange} value={value} />;
}
